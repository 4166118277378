import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/index.vue'),
    meta: {
      name: 'Автозапчасть КАМАЗ 35',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact/index.vue'),
    meta: {
      breadcrumb: 'Контактная информация',
      name: 'Контактная информацияя',
    },
  },
  {
    path: '/news',
    name: 'news',
    children: [
      {
        path: '',
        name: 'news.index',
        component: () => import(/* webpackChunkName: "news" */ '../views/news/index.vue'),
        meta: {
          breadcrumb: 'Новости',
          name: 'Новости',
        },
      },
      {
        path: ':id',
        name: 'news.detail',
        component: () => import(/* webpackChunkName: "news" */ '../views/news/detail.vue'),
        props: true,
        meta: {
          breadcrumb: 'Чтение новости',
          name: 'Новости',
        },
      },
    ]
  },
  {
    path: '/order',
    name: 'order',
    children: [
      {
        path: '',
        name: 'order.index',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/index.vue'),
        meta: {
          breadcrumb: 'Корзина',
          name: 'Корзина',
        },
      },
      {
        path: 'making',
        name: 'order.making',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/making/index.vue'),
        meta: {
          breadcrumb: 'Оформление заказа',
          name: 'Корзина',
        },
      },
    ]
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/index.vue'),
    meta: {
      breadcrumb: 'Политика конфиденциальности',
      name: 'Политика конфиденциальности',
    },
  },
  {
    path: '/products',
    name: 'products',
    children: [
      {
        path: '',
        name: 'products.index',
        component: () => import(/* webpackChunkName: "products" */ '../views/products/index.vue'),
        meta: {
          breadcrumb: 'Товары',
          name: 'Товары',
        },
      },
      {
        path: ':id',
        name: 'products.detail',
        component: () => import(/* webpackChunkName: "products" */ '../views/products/detail.vue'),
        props: true,
        meta: {
          breadcrumb: 'Просмотр товара',
          name: 'Товары',
        },
      },
      {
        path: 'counterfeit',
        name: 'products.counterfeit',
        component: () => import(/* webpackChunkName: "products" */ '../views/products/counterfeit.vue'),
        meta: {
          breadcrumb: 'Защита от контрафакта',
          name: 'Защита от контрафакта',
        },
      },
      {
        path: 'guarantee',
        name: 'products.guarantee',
        component: () => import(/* webpackChunkName: "products" */ '../views/products/guarantee.vue'),
        meta: {
          breadcrumb: 'Гарантия на запасные части',
          name: 'Гарантия на запасные части',
        },
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(to => {
  document.title = to.meta.name as string
  window.scrollTo({ top: 0, behavior: 'smooth'})
})

export default router
