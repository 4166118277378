<template>
  <div class="layout">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from './components/app/Header.vue'
import Footer from './components/app/Footer.vue'
import { IOrderItem } from './api/interfaces/order'
import { useStore } from 'vuex'

export default defineComponent({ 
    name: 'ContactIndex',

    components: {
      Header,
      Footer,
    },

    setup() {
      const store = useStore()
      let order = null

      if (localStorage) {
          let storageOrder = localStorage.getItem('order')

          if (storageOrder) {
            order = JSON.parse(storageOrder) as IOrderItem[] | null
          }
      }

      if (order) {
        store.commit('setOrder', order)
      }
    }
})
</script>
