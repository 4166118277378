<template>
    <footer class="footer">
        <div class="footer__wrapper wrapper-content">
            <div class="footer__info">
                <div class="footer__logo">
                    <img src="@/assets/images/logo.png" alt="logo">
                </div>
                <div>
                    <p class="footer__text">© 2024, ООО "Камаз35"</p>
                    <a 
                        href="https://kamaz.ru/" 
                        class="footer__link" 
                        target="_blank"
                        rel="nofollow"
                    >ПАО "Камаз"</a>
                    <div>
                        <a 
                            href="https://azkamaz.ru/" 
                            class="footer__link" 
                            target="_blank"
                            rel="nofollow"
                        >ООО «АвтоЗапчасть КАМАЗ»</a>
                    </div>
                </div>
            </div>
            <div class="footer__map">
                <div class="footer__map-item">
                    <router-link to="/" class="footer__link footer__link--main">
                        Главная
                    </router-link>
                    <router-link to="/privacy" class="footer__link">
                        Обработка персональных данных
                    </router-link>
                </div>
                <div class="footer__map-item">
                    <router-link to="/products" class="footer__link footer__link--main">
                        Запасные части
                    </router-link>
                    <router-link to="/products/counterfeit" class="footer__link">
                        Защита от контрафакта
                    </router-link>
                    <router-link to="/products/counterfeit" class="footer__link">
                        Сувенирная продукция
                    </router-link>
                </div>
                <div class="footer__map-item">
                    <router-link to="/news" class="footer__link">
                        Новости
                    </router-link>
                    <router-link to="/contact" class="footer__link">
                        Контактная информация
                    </router-link>
                </div>
            </div>
            <div class="footer__contact">
                <a href="tel:+79315171222" class="footer__tel">+7 (931) 517-12-22</a>
                <p class="footer__text">г. Череповец, Северное 41а</p>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({ 
    name: 'FooterIndex',
})
</script>