<template>
    <nav :class="{'open': isOpenSidebar}" class="nav">
        <span @click="closeSidebar" class="nav__close-button">
            <el-icon><CloseBold /></el-icon>
        </span>
        <ul class="nav__links">
            <li class="nav__li">
                <router-link to="/" class="nav__link" active-class="nav__link--active">
                    Главная
                </router-link>
            </li>
            <li class="nav__li">
                <router-link  Link to="/products" exact class="nav__link" active-class="nav__link--active">
                    Запасные части
                </router-link>          
            </li>
            <li class="nav__li">
                <a href="https://kamaz.ru/press/releases/" target="_blank" rel="noopener noreferrer" class="nav__link">
                    Новости
                </a>
            </li>
            <li class="nav__li">
                <router-link to="/contact" class="nav__link" active-class="nav__link--active">
                    Контактная информация
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { CloseBold } from '@element-plus/icons-vue'

export default defineComponent({ 
    name: 'NavIndex',
    
    components: {
        CloseBold,
    },

    props: {
        isOpenSidebar: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['close-sidebar'],

    setup(props, { emit }) {
        function closeSidebar() : void {
            emit('close-sidebar')
        }

        return {
            closeSidebar,
        }
    },
})
</script>