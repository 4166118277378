<template>
    <header class="header">
        <div class="wrapper-content header__wrapper">
            <div class="header__logo">
                <img src="@/assets/images/logo.png" alt="logo" class="logo">
            </div>
            <div class="header__data">
                <div class="header__info">
                    <div class="header__title">
                        <h1 class="title">Автозапчасть Камаз35</h1>
                        <span class="subtitle">Официальный дилер ПАО «КАМАЗ</span>
                    </div>
                    <div class="header__buttons">
                        <div @click="goToOrder" class="header__order">
                            <el-badge :value="sizeOrder || 0" type="primary">
                                <el-icon v-if="!sizeOrder">
                                    <ShoppingCart />
                                </el-icon>
                                <el-icon v-else>
                                    <ShoppingCartFull />
                                </el-icon>
                            </el-badge>
                        </div>
                        <div @click="isOpenSidebar = true" class="header__burger">
                            <el-icon><Menu /></el-icon>
                        </div>
                    </div>
                </div>
                <div class="header__nav">
                    <Nav @close-sidebar="isOpenSidebar = false" :is-open-sidebar="isOpenSidebar" />
                </div>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { ShoppingCart, ShoppingCartFull, Menu } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Nav from './Nav.vue'

export default defineComponent({ 
    name: 'HeaderIndex',

    components: {
        ShoppingCart,
        ShoppingCartFull,
        Menu,
        Nav,
    },

    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        
        let isOpenSidebar = ref<boolean>(false)

        function goToOrder() : void {
            router.push('/order')
        }

        watch(() => route.path, () => {
            setTimeout(() => isOpenSidebar.value = false, 300)
        })

        return {
            sizeOrder: computed(() => store.getters.getSizeOrder),

            goToOrder,

            isOpenSidebar,
        }
    },
})
</script>