import { createStore } from 'vuex'
import type { IProduct } from '@/api/interfaces/product'
import type { IOrderItem } from '@/api/interfaces/order'

export default createStore({
  state: {
    order: [] as IOrderItem[],
    detailProduct: null as IProduct | null,
  },
  getters: {
    hasOrder(state) : boolean {
      return state.order.length > 0
    },
    getSizeOrder(state) : number | null {
        if (state.order.length === 0) return null

        return state.order.reduce((acc, item) => acc += item.quantity, 0)
    },
  },
  mutations: {
    addProductInOrder(state, product: IProduct) {
      const index = state.order.findIndex(item => item.product_id === product.id)

        if (index >= 0) {
            state.order[index].quantity += 1
        } else {
            const orderItem: IOrderItem = {
                product_id: product.id,
                name: product.name,
                description: product.description,
                images: product.images,
                quantity: 1,
                price: product.price,
            }

            state.order.push(orderItem)
        }

        localStorage.setItem('order', JSON.stringify(state.order))
    },

    deleteProductInOrder(state, id: number) {
      const index = state.order.findIndex(item => item.product_id === id)

      if (index) {
          if (state.order[index].quantity > 1) {
              state.order[index].quantity -= 1
          } else {
              state.order.slice(index, 1)
          }
      }

      localStorage.setItem('order', JSON.stringify(state.order))
    },

    setOrder(state, order: IOrderItem[]) {
      state.order = order
      localStorage.setItem('order', JSON.stringify(state.order))
    },

    clearOrder(state) {
      state.order = []
      localStorage.setItem('order', '')
    },

    setDetailProduct(order, product: IProduct | null) {
      order.detailProduct = product
    },
  },
})
